(function (app) {

    // this removes the query string parameters from the URL, since we will be repeating them in the angular state params
    // need to do this right away, not in an angular config/run callback - we need to have grabbed and updated the query string params before ui-router tries to interact with it
    // but only do the replaceState if we aren't currently in a ui state, otherwise we will lose our ui route on page refresh
    var params = parseQuery(window.location.search);
    if (!window.location.hash) window.history.replaceState(null, null, window.location.pathname);

    /*
     * Root state is the top-level state for all user-facing states: login/mfa/settings etc, but not client admin (creating redirect uris etc)
     * clientID/theme are passed in as optional parameters, and (if provided) will resolve a client object that all substates can reference
     * additionally, a "title" can be passed in instead (if there is no client) to show, such as "Admin" or "Settings"
     */

    app.config(["$stateProvider", "$urlRouterProvider", "parameterResolverProvider", function ($stateProvider, $urlRouterProvider, parameterResolverProvider) {
        $stateProvider.state("Root", {
            url: "/App?{clientId}?{theme}&{title}&{postLoginReturnUrl}&{registrationToken}&{loginHint}&{passwordResetToken}&{returnState}&{returnParams:json}&{autoRedirect:bool}&{hideFig:bool}",
            abstract: true,
            component: "root",
            resolve: {
                loginHint: parameterResolverProvider.resolve("loginHint"),
                postLoginReturnUrl: parameterResolverProvider.resolve("postLoginReturnUrl"),
                registrationToken: parameterResolverProvider.resolve("registrationToken"),
                autoRedirect: parameterResolverProvider.resolve("autoRedirect"),
                hideFig: parameterResolverProvider.resolve("hideFig"),
                client: ["clientApi", "$transition$", function (clientApi, $transition$) {
                    var clientId = $transition$.params().clientId;
                    var theme = $transition$.params().theme;
                    if (!clientId) return;
                    return clientApi.getBasicInfo(clientId, theme);
                }],
                colorScheme: ["client", function (client) {
                    if (client) return client.ColorScheme;
                    return {
                        // default if no theme provided
                        BackgroundColor: "fafafa",
                        PrimaryColor: "#754545"
                    };
                }],
                clientId: ["client", function (client) {
                    if (client) return client.ClientId;
                }],
                pageTitle: ["client", "$transition$", function (client, $transition$) {
                    // using pageTitle instead of just title because ui-router places an attribute on the html element 'title="::$resolve.title"` which will show text on hover
                    // this represents what is shown above the main div, the client logo/name/title
                    return (client && client.Name) || $transition$.params().title;
                }],
                returnState: parameterResolverProvider.resolve("returnState"),
                returnParams: parameterResolverProvider.resolve("returnParams"),
            },
            onEnter: ["pageTitle", "$document", function (pageTitle, $document) {
                $document[0].title = pageTitle;
            }]
        });

        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get("$state");
            if (params.errorId) {
                var stateParams = { errorId: params.errorId };
                return $state.go("Root.Error", stateParams);
            }
            else if (params.settings) {
                var stateParams = { clientId: params.client_id, theme: params.theme, postLoginReturnUrl: params.return_url };
                return $state.go("Root.Settings", stateParams);
            }
            else if (params.password_reset_expired) {
                return $state.go("Root.ResetExpired", { title: "Password Reset", clientId: params.client_id, theme: params.theme });
            }
            else if (params.password_reset_token) {
                return $state.go("Root.ResetVerify", {
                    passwordResetToken: params.password_reset_token,
                    postLoginReturnUrl: params.postLoginReturnUrl,
                    clientId: params.client_id,
                    theme: params.theme
                });
            }
            else if (params.admin) {
                return $state.go("Admin.Clients");
            }
            else if (params.idsrvReturnUrl) {
                // redirected here from /connect/authorize, unpack oauth parameters and go to client substate
                var oauthParams = parseQuery(params.idsrvReturnUrl.substring(params.idsrvReturnUrl.indexOf("?")));
                var stateParams = {
                    clientId: oauthParams.client_id,
                    loginHint: oauthParams.login_hint,
                    postLoginReturnUrl: params.idsrvReturnUrl,
                    theme: oauthParams.theme,
                    autoRedirect: oauthParams.auto_redirect === "true",
                    hideFig: oauthParams.hideFig === "true"
                };
                if (oauthParams.registration_token) {
                    stateParams.registrationToken = oauthParams.registration_token;
                    return $state.go("Root.SignUp", stateParams);
                }
                else if (params.password_reset_token) {
                    stateParams.passwordResetToken = params.password_reset_token;
                    return $state.go("Root.ResetVerify", stateParams);
                }
                else {
                    return $state.go("Root.Login", stateParams);
                }
            }
            else if (params.samlReturnUrl) {
                var stateParams = {
                    postLoginReturnUrl: params.samlReturnUrl,
                    autoRedirect: true
                };
                return $state.go("Root.Login", stateParams);
            }
            else {
                // last resort, just go to settings page with no returnUrl
                $state.go("Root.Settings");
            }
        });
    }]);

    app.component("root", {
        templateUrl: "/App/Root/root.html",
        bindings: {
            client: "<", // optional
            colorScheme: "<",
            pageTitle: "<"
        }
    });

    // https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

})(window.app);